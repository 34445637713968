<template>

  <div class="page-background" v-if="company">
    <div class="container mainPage">
      <div style="text-align: center;width: 300px">
        <img alt="shop-logo" class="shop-logo mb-3" v-bind:src="'https://api.aw-erp.com/v1/file/' + company.logo">
          <div class="form mb-3">
            <vue-tel-input class="form-control d-flex" :value="phone" @input="onInput" mode="international"></vue-tel-input>
          </div>

          <button :disabled="!phoneObj?.valid" @click="submit()" class="btn btn-secondary w-100">Submit</button>
      </div>
    </div>

    <footer>
        <div class="text-center p-4">
            Powered by
            <a class="text-reset fw-bold" href="https://awmenu.com/"><img style="max-width: 100px;" src="/images/logo/e-menu.png" alt="e-menu" /></a>
        </div>
    </footer>
  </div>
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { registerCustomer} from '../services/api';
    import {fetchCompany} from '../services/api';
    import router from "@/router";
    import { VueTelInput } from 'vue3-tel-input'
    import 'vue3-tel-input/dist/vue3-tel-input.css'
    export default {
        name: 'LoginPage',
        props: {
            productId: String,
        },
        data() {
          return {
            phone: '',
            phoneObj: {},
          };
        },
        components: {
          VueTelInput,
        },
        methods: {
          async submit() {
            if(!this.phoneObj.valid) {
              return;
            }
            await router.push('/login-otp/');
            //this.$emit('submit', this.phone)
            console.log(this.phoneObj);
            try {
              localStorage.setItem('nationalNumber',this.phoneObj.nationalNumber);
              localStorage.setItem('countryCallingCode', this.phoneObj.countryCallingCode);

              let res = await registerCustomer(
                    parseInt(this.phoneObj.nationalNumber),
                    parseInt(this.phoneObj.countryCallingCode) );

              if(res) {
                await router.push('/login-otp/');
              }
            } catch {
              console.error('Error Register User');
            }
          },
          onInput(phone, phoneObject) {
            if (phoneObject?.formatted) {
              this.phone = phoneObject.formatted
              this.phoneObj = phoneObject

              if(this.phoneObj.valid) {
                this.error = '';
              }
            }
          }
        },
        setup() {
            const company = ref(null);
            const viewStore = () => {
              router.push('/CategoriesList/');
            }

            const login = () => {
              router.push('/login/');
            }

            onMounted(async () => {
                try {
                  let companyRes = await fetchCompany();
                  company.value = companyRes.data?.result;
                } catch {
                    console.error('Error fetching Shop Data');
                }

                const searchElement = document.getElementById('search');

                if (searchElement) {
                    searchElement.focus();
                }
            });

            return { 
              company,
              viewStore,
              login,
            };
        },
    }
  </script>
  

<style scoped>
  @import '../assets/css/main.css';

  .mainPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90dvh;
  }



  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
</style>