<template>

  <div class="page-background" v-if="company">
    <div class="container mainPage">
      <div style="text-align: center;width: 300px">
        <img alt="shop-logo" class="shop-logo mb-3" v-bind:src="'https://api.aw-erp.com/v1/file/' + company.logo">
        <h6 class="mt-3" style="text-align: center">{{company.name}}</h6>
        <button @click="viewStore" class="btn btn-secondary w-100 mt-2">View Menu</button>
        <button @click="login" v-if="!isAuthenticated" class="btn btn-outline-secondary w-100 mt-3">Login</button>
      </div>
    </div>

    <footer>
        <div class="text-center p-4">
            Powered by
            <a class="text-reset fw-bold" href="https://awmenu.com/"><img style="max-width: 100px;" src="images/logo/e-menu.png" alt="aw-menu" /></a>
        </div>
    </footer>
  </div>
</template>

<script>
    import { ref, onMounted } from 'vue'; 
    import {fetchCompany} from '../services/api';
    import router from "@/router";
    export default {
        name: 'HomePage',
        props: {
            productId: String,
        },
        setup() {
            const company = ref(null);
            const isAuthenticated = localStorage.getItem('isAuthenticated') === "true";

          const viewStore = () => {
              router.push('/CategoriesList/');
            }

            const login = () => {
              router.push('/login/');
            }

            onMounted(async () => {
                try {
                  let companyRes = await fetchCompany();
                  company.value = companyRes.data?.result;
                } catch {
                    console.error('Error fetching Shop Data');
                }
            });

            return { 
              company,
              viewStore,
              login,
              isAuthenticated
            };
        },
    }
  </script>
  

<style scoped>
  @import '../assets/css/main.css';

  .mainPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90dvh;
  }

  body {
    margin-bottom: 0;
  }

</style>