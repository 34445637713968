<template >
    <NavBar :allowShare="true" :profile="true"  :cart="true" :back="true" title="Categories"></NavBar>
    <SearchBar :searchItems="searchItems"></SearchBar>
    <div v-if="load" class="px-0 bg-white">

    <!-- Slider -->
    <div v-if="enable_slider" id="marketing-banners" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="https://assets.sdgcdn.com/10011698/creative-1700567074446-iPhone_15_Pro__Buy_Back_Web_Banner.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="https://s.sdgcdn.com/10/2023/11/Web-Banner-%E2%80%93-Arabic-English-Both-Required-1.jpeg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="https://s.sdgcdn.com/10/2023/11/Q4-23-_SHARAF-DG-BH-INTEL-MDF-BANNER_-_2880X720px.png" class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <!-- end slider -->
    <div v-cloak>
      <div class="container-fluid">
        <div class="row gx-sm-5 g-4 m-0">
          <div class="col-6" v-for="category of categories" :key="category.id">
            <div class="card border-0 rounded-0" @click="showItems(category.id)">
              <div class="text-center mb-2">
                <img class="img-fluid limit-img" :src="`https://api.aw-erp.com/v1/file/${category.featuredImage}`">
              </div>
              <div class="card-body">
                <h6 class="card-title">{{category.name}}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <LoadingIndicator :loading="!load"></LoadingIndicator>
</template>

<script>
import { fetchCategories , fetchCompany} from '../services/api';
import router from '../router.js';
import SearchBar from "@/components/SearchBar.vue";
import NavBar from "@/components/NavBar.vue";
import * as Bootstrap from "bootstrap";
import LoadingIndicator from "@/components/Loading.vue";
const searchDelay = 1000;
let timer;

export default {
  name: 'HomePage',
  components: {LoadingIndicator, NavBar, SearchBar},
  data() {
    return {
      load: false,
      categories:[],
      company:{},
      search:'',
      lang: 'en',
      enable_slider: false,
      error: {
        show: false,
        title: null
      }
    }
  },
  props: {
    productId: String,
  },
  mounted(){
    const enable_slider = false;
    if(enable_slider) {
      let myCarousel = document.querySelector('#marketing-banners')
      new Bootstrap.Carousel(myCarousel)
    }
  },
  methods: {
    searchItems(input) {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      const pushRouter = () => {
        router.push({name: 'searchProduct', params:{search: input}});
      };

      timer = setTimeout(pushRouter, searchDelay);
    },
    showItems(catId) {
      router.push('/products/' + catId);
    },
    translate(enVar, arVar){
      if(this.lang === 'ar' && arVar != null)
      return arVar;
      return enVar;
    },
    formatDate(date) {
      return date.substr(0,date.search('T'));
    }
  },
  async created() {
    try {
      let companyRes = await fetchCompany();
      this.company = companyRes.data?.result;

      let response = await fetchCategories();
      this.categories = response.data?.result;
    } catch (error) {
      console.error('Error company product details:', error);
    }
    this.load = true;
  },
}
</script>


<style >
@import '../assets/css/main.css';

#app {
  background-color:#F2F2F2;
}
.carousel-item img {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.center-cropped {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.category-card {
  cursor: pointer;
  height: 300px;
}

.card-img {
  background-size: contain;
  background-repeat: no-repeat;
}

.card-img-top {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card {
  --bs-card-border-color: rgb(199 199 199 / 24%);
  --bs-card-border-radius: 12px;
}

.card-title {
  text-align: center;
}

@media (max-width:641px) {
  .center-cropped {
    height: 100px;
  }
  .carousel-item img {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
}
</style>