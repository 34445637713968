import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";

const apiUrl = 'https://api.aw-erp.com/v1/eMenu';

const queryParams = new URLSearchParams(location.search);
let hash =  queryParams.get('hash');

if(hash != localStorage.getItem('hash')) {
  localStorage.setItem('hash', hash);
}

let noHash = ['/404','/providers'];

if(!hash && (!noHash.includes(window.location.pathname)) ) {
    window.location.replace('/404');
}




// let hash = 'M2QzNWZkYTRkNGQ1OGJkODM3NWE5NjgwMDkzMWE5NTIyYWEyZjgxMmJhYzkzYTFmZDIyNDBkYjllZThjZWM2MQ==';

const config = {
      headers:{
          hash: hash ,
      }
  };


const api = axios.create({
  baseURL: apiUrl, 
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

api.interceptors.response.use((response) => {
    return response
}, (error) => {
    const respCode = error.response.data?.respCode ?? '500';
    const respDesc = error.response.data?.respDesc ?? 'oops something went wrong';
    if (error.response) {
        notify({
            title: respCode,
            text: respDesc,
            group:"error",
        });
    }
    return false
})


export const fetchCompany = async () => {
  return await api.get('/company',config)
};

export const fetchCategories = async () => {
  return await api.get('/categories',config)
};

export const fetchProducts = async (categoryId, search, tags) => {
    categoryId = categoryId == null ? '' : categoryId;
  return await api.get(`/items?category=${categoryId}&search=${search}&tags=${tags}`,config);
};

export const fetchProviders = async () => {
    return await api.get(`/providers`,config);
};

export const fetchCart = async () => {
    return await api.get(`/cart`,config);
};

export const fetchAddress = async () => {
    return await api.get('/addressList', config);
}

export const saveAddress = async (address) => {
    return await api.post(`/address/`, address, config)
};


export const fetchProfile = async () => {
    return await api.get('/profile', config);
}

export const updateProfile = async (profileData) => {
    return await api.put('/profile', profileData ,config)
}

export const fetchProductDetails = async (productId) => {
  return await api.get(`/item/${productId}`,config);
};


export const registerCustomer = async (nationalNumber,dialingCode) => {
    return await api.post(`/register/`, {mobile: nationalNumber, dialingCode: dialingCode}, config)
};

export const validateOTP = async (nationalNumber,dialingCode,otp) => {
    return await api.post(`/register/`, {mobile: nationalNumber, dialingCode: dialingCode,otp: otp}, config)
};

export const addToCart = async (id,qty,note) => {
    let items = [];
    items.push({id: id, qty: qty,note: note});
    return await api.post(`/cart/`, {items:items}, config)
};

export const removeFromCart = async (id) => {
    return await api.delete(`/cart/${id}`, config)
};

export const checkout = async (addressId) => {
    return await api.post('/order', {addressId:addressId}, config);
}

export const fetchOrders = async () => {
    return await api.get(`/orders`,config);
};

export const fetchOrder = async (orderId) => {
    return await api.get(`/order/${orderId}`,config);
};