<template>

  <div class="page-background" v-if="company">
    <div class="container mainPage">
      <div style="width: 350px">
        <div style=" text-align: center;">
        <img alt="shop-logo" class="shop-logo mb-3" v-bind:src="'https://api.aw-erp.com/v1/file/' + company.logo">

          <div class="form mb-3">
            <h3 class="text-h6 mb-1">Mobile phone verification</h3>

            <div class="text-body-2 font-weight-light mb-4">
              Enter the code we just sent to your mobile phone <span class="font-weight-black" style="font-weight:800;">{{ nationalNumber }}</span> <a href="#" @click="login">Change phone number</a>

            </div>
          </div>
            <div style="display: flex; flex-direction: row">
              <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  v-model="otp"
                  separator=""
                  error
                  :num-inputs="6"
                  :should-auto-focus="true"
                  input-type="numeric"
                  :placeholder="['*', '*', '*', '*','*','*']"
                  @on-complete="submit"
              />
            </div>
          </div>

          <div class="text-caption mt-4" style="text-align: center">
            Didn't receive the code?
            <a href="#" v-if="!counting" @click="resend">
              Re-send
            </a>
            <vue-countdown v-if="counting" :time="2 * 60 * 1000" v-slot="{seconds}" @end="onCountdownEnd">
              {{ String(seconds).padStart(2, '0') }}
            </vue-countdown>
          </div>

      </div>
    </div>

    <footer>
        <div class="text-center p-4">
            Powered by
            <a class="text-reset fw-bold" href="https://awmenu.com/"><img style="max-width: 100px;" src="/images/logo/e-menu.png" alt="e-menu" /></a>
        </div>
    </footer>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import {fetchCompany, registerCustomer, validateOTP} from '../services/api';
    import router from "@/router";
    import VOtpInput from "vue3-otp-input";
    import VueCountdown from '@chenfengyuan/vue-countdown';
    export default {
        name: 'LoginOtp',
        props: {
            productId: String,
        },
        components: {
          VOtpInput,
          VueCountdown
        },
        methods: {
          async submit(otp) {
            let res = await validateOTP(
                parseInt(this.nationalNumber),
                parseInt(this.countryCallingCode),
                parseInt(otp)
            );
            if(res) {
              localStorage.setItem('authToken',res.data?.result.token);
              localStorage.setItem('isAuthenticated','true');
              this.$notify({
                title: "Logged in",
                text: `welcome ${this.nationalNumber}`,
              });
              this.viewStore();
            } else {
              this.otp = '';
            }
          },
          onCountdownEnd: function () {
            this.counting = false;
          },
          resend: async function () {
            this.counting = true;
            let res = await registerCustomer(
                parseInt(this.nationalNumber),
                parseInt(this.countryCallingCode)
            );
            if(res) {
              this.$notify({
                title: "SMS sent",
                text: `you should receive an sms on ${this.nationalNumber}`,
              });
            }
          },
        },
        data() {
          return {
            counting: true,
            otp: '',
          };
        },
        setup() {
            const company = ref(null);
            const nationalNumber = localStorage.getItem('nationalNumber');
            const countryCallingCode = localStorage.getItem('countryCallingCode');

            const viewStore = () => {
              router.push('/CategoriesList/');
            }

            const login = () => {
              router.push('/login/');
            }

            onMounted(async () => {

                if(!nationalNumber) {
                  login();
                }

                try {
                  let companyRes = await fetchCompany();
                  company.value = companyRes.data?.result;
                } catch {
                    console.error('Error fetching Shop Data');
                }

                const searchElement = document.getElementById('search');

                if (searchElement) {
                    searchElement.focus();
                }
            });

            return { 
              viewStore,
              login,
              company,
              nationalNumber,
              countryCallingCode
            };
        },
    }
  </script>
  

<style>
  @import '../assets/css/main.css';

  .mainPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90dvh;
  }

  .otp-input {
    width: 40px;
    height: 50px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  /* Background colour of an input field with value */
  .otp-input.is-complete {
    background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
</style>