import { createRouter, createWebHistory } from 'vue-router'
import ProductsList from './components/ProductsList.vue'
import ProductDetails from './components/ProductDetails.vue'
import Home from "@/components/Home.vue";
import CategoriesList from "@/components/CategoriesList.vue";
import login from "@/components/Login.vue";
import loginOtp from "@/components/LoginOtp.vue";
import CartList from "@/components/CartList.vue";
import MyProfile from "@/components/MyProfile.vue";
import Address from "@/components/NewAddress.vue";
import OrderList from "@/components/OrderList.vue";
import ErrorPage from "@/components/ErrorPage.vue";
import ProvidersList from "@/components/ProvidersList.vue";
import OrderDetails from "@/components/OrderDetails.vue";


const routes = [
  { path: '/', component: Home , name:'home'},
  { path: '/CategoriesList', component: CategoriesList, name:'categories' },
  { path: '/login', component: login ,name:'login' },
  { path: '/login-otp', component: loginOtp , name:'loginOtp'},
  { path: '/products/:catId', component: ProductsList , name:'productList'},
  { path: '/providers/', component: ProvidersList , name:'providersList'},
  { path: '/productsS/:search', component: ProductsList, name: 'searchProduct' },
  { path: '/product/:id', component: ProductDetails, props: true , name:'productDetails' },
  { path: '/cart/:', component: CartList, name:'cart' },
  { path: '/myProfile/', component: MyProfile, name:'myProfile' },
  { path: '/newAddress/', component: Address, name:'newAddress' },
  { path: '/orders/', component: OrderList, name:'orderList' },
  { path: '/order/:id', component: OrderDetails, name:'orderDetails' },
  { path: '/provider/', component: Home, name:'provider'},
  { path: '/:notFound', component: ErrorPage, name:'errorPage'},

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const hash = localStorage.getItem('hash');
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  if (isAuthenticated === "true" && (to.path === '/login/' || to.path === '/login-otp/')) {
    return {
      path: '/' ,
      query: {
        hash: hash
      }
    }
  }
})

const originalPush = router.push;

// add the hash on the header
router.push = function(location, onComplete, onAbort) {
  let hash = localStorage.getItem('hash');

  if(hash) {
    if(typeof location === 'object' && location !== null && (location.path === "/provider/" || location.path === "/providers/") ) {
      localStorage.setItem('hash',hash);
      originalPush.call(this, location, onComplete, onAbort);
    }
    else if (typeof location === 'object' && location !== null && location.query) {
      location.query = { ...location.query, hash: hash };
    }
    else if(location !=null && typeof location.params === 'object') {
      location.params['hash'] = hash;
    }
    else if(location != null) {
      location = {
        path: location,
        query: {
          hash: hash
        }
      }
    }
  }

  originalPush.call(this, location, onComplete, onAbort);
};


export default router